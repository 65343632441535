import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/public/about.webp");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/public/co-robimy-2.webp");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/public/co-robimy-3.webp");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/public/co-robimy.webp");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/public/ebook.webp");
;
import(/* webpackMode: "eager" */ "/Users/dawid/Work/aw-sport/src/components/animation/AnimateBoxSlideDown.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/dawid/Work/aw-sport/src/components/Nav.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/Users/dawid/Work/aw-sport/src/components/OpinionSlider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TextGenerateEffect"] */ "/Users/dawid/Work/aw-sport/src/components/ui/text-generate-effect.tsx");
