"use client";
import { motion } from "framer-motion";
import React from "react";

const AnimateBoxSlideDown = ({
  children,
  delay,
  margin = "-200px",
}: {
  children: React.ReactNode;
  delay?: number;
  margin?: string;
}) => {
  return (
    // <motion.div
    //   transition={{
    //     duration: 0.6,
    //     delay: delay ? delay : 0,
    //   }}
    //   initial={{
    //     opacity: 0,
    //     y: 200,
    //   }}
    //   whileInView={{
    //     opacity: 1,
    //     y: 0,
    //   }}
    //   viewport={{
    //     once: true,
    //     margin: margin,
    //   }}
    // >
    //   {children}
    // </motion.div>
    <div>{children}</div>
  );
};

export default AnimateBoxSlideDown;
