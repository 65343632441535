import React from "react";

const ChevronIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="6"
      viewBox="0 0 9 6"
      fill="none"
    >
      <path
        d="M8 1L5.25258 4.13991C4.85416 4.59524 4.14583 4.59524 3.74742 4.13991L1 1"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default ChevronIcon;
