export const links = {
  homePage: "/",
  privacyPolicy: "/polityka-prywatnosci",
  aboutUs: "/kim-jestesmy",
  awSportClass: "/aw-sport-class",
  awSportCamp: "/aw-sport-camp",
  awSportTeam: "/aw-sport-team",
  ebooks: "/ebooki",
  ebookBestia1: "/ebook-bestia1",
};
