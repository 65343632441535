"use client";
import { links } from "@/constants";
import ChevronIcon from "@/icons/ChevronIcon";
import Link from "next/link";
import { usePathname } from "next/navigation";
import { useEffect, useState } from "react";

const Nav = () => {
  const pathname = usePathname();

  const [isOpen, setIsOpen] = useState(false);
  const [subMenuOpen, setSubMenuOpen] = useState(false);
  const genericHamburgerLine = `h-1 w-8 my-[3px] rounded-full bg-awSea transition ease transform duration-300`;

  useEffect(() => {
    // Block scroll
    document.body.style.overflow = isOpen ? "hidden" : "auto";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav className={`flex  items-center  relative`}>
      {/* Mobile */}
      <div
        className={`flex top-[5.5rem] h-full z-50 justify-center left-0 w-full  fixed xl:hidden transition-all bg-awIntenseDark ${
          isOpen ? "translate-x-0" : "-translate-x-full"
        }`}
      >
        <div className="flex flex-col gap-12 pt-20 w-full items-center">
          <div className="xl:px-6 xl:mx-2 xl:px-10 xl:mx-4">
            <Link
              className="hover:text-awSea  hover:-translate-y-1 transition-all block"
              href={links.aboutUs}
            >
              Kim jesteśmy?
            </Link>
          </div>
          <div className="group w-full flex justify-center flex-col relative xl:px-6 xl:mx-2 xl:px-10 xl:mx-4">
            <span
              className="flex w-full text-center justify-center gap-4 items-center cursor-pointer"
              onClick={() => setSubMenuOpen(!subMenuOpen)}
            >
              Co robimy? <ChevronIcon />
            </span>
            <div
              className={`py-6 bg-awIntenseDark/90 gap-10 flex-col top-full ${
                subMenuOpen ? "flex" : "hidden"
              }  w-full transition-all`}
            >
              <Link
                className="w-full text-center hover:translate-x-2 transition-all"
                href={links.awSportCamp}
              >
                AW-Sport <span className="text-awSea">Camp</span>
              </Link>
              <Link
                className="w-full text-center hover:translate-x-2 transition-all"
                href={links.awSportTeam}
              >
                AW-Sport <span className="text-awSea"> Team </span>
              </Link>
              <Link
                className="w-full text-center hover:translate-x-2 transition-all"
                href={links.awSportClass}
              >
                AW-Sport <span className="text-awSea">Class</span>
              </Link>
            </div>
          </div>
          <div className=" xl:px-10 xl:mx-4">
            <Link
              className="hover:text-awSea  hover:-translate-y-1 transition-all block"
              href={links.ebooks}
            >
              Nasze ebooki
            </Link>
          </div>
          <div>
            <Link
              onClick={() => setIsOpen(false)}
              className="btn-primary"
              href="#kontakt"
            >
              <span className="skew-x-[35deg] block">Napisz do nas</span>
            </Link>
          </div>
        </div>
      </div>
      {/* End mobile */}
      <div className="hidden xl:flex items-center">
        <div className=" xl:px-10 xl:mx-4">
          <Link
            className="hover:text-awSea whitespace-nowrap  hover:-translate-y-1 transition-all block"
            href={links.aboutUs}
          >
            Kim jesteśmy?
          </Link>
        </div>
        <div className="group relative  xl:px-10 xl:mx-4">
          <span className="flex gap-4 whitespace-nowrap items-center cursor-pointer">
            Co robimy? <ChevronIcon />
          </span>
          <div className="hidden opacity-0 absolute py-6 bg-awIntenseDark/90 gap-10 flex-col top-full group-hover:flex group-hover:opacity-100 w-full transition-all">
            <Link
              className="w-full text-center hover:translate-x-2  transition-all"
              href={links.awSportCamp}
            >
              AW-Sport <span className="text-awSea">Camp</span>
            </Link>
            <Link
              className="w-full text-center hover:translate-x-2 transition-all"
              href={links.awSportTeam}
            >
              AW-Sport <span className="text-awSea"> Team </span>
            </Link>
            <Link
              className="w-full text-center hover:translate-x-2 transition-all"
              href={links.awSportClass}
            >
              AW-Sport <span className="text-awSea">Class</span>
            </Link>
          </div>
        </div>
        <div className=" xl:px-10 xl:mx-4">
          <Link
            className="hover:text-awSea whitespace-nowrap hover:-translate-y-1 transition-all block"
            href={links.ebooks}
          >
            Nasze ebooki
          </Link>
        </div>
        <div className="xl:pl-10">
          <Link
            className="btn-primary"
            href={pathname === links.ebookBestia1 ? "/#kontakt" : "#kontakt"}
          >
            <span className="skew-x-[35deg] block whitespace-nowrap">
              Napisz do nas
            </span>
          </Link>
        </div>
      </div>
      <div className="relative xl:hidden block">
        <button
          className="flex flex-col h-12 w-12 rounded justify-center items-center group"
          onClick={() => setIsOpen(!isOpen)}
          aria-label={isOpen ? "zamknij" : "otworz"}
        >
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "rotate-45 translate-y-[10px] opacity-80 group-hover:opacity-100"
                : "opacity-80 group-hover:opacity-100"
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen ? "opacity-0" : "opacity-80 group-hover:opacity-100"
            }`}
          />
          <div
            className={`${genericHamburgerLine} ${
              isOpen
                ? "-rotate-45 -translate-y-[10px] opacity-80 group-hover:opacity-100"
                : "opacity-80 group-hover:opacity-100"
            }`}
          />
        </button>
      </div>
    </nav>
  );
};

export default Nav;
